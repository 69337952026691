import { UiService } from 'src/app/core/ui-service/ui.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { DIALOG_CONFIRM_DEFAULT_TITLE } from '../../constant';
import { switchMap } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { TranslocoModule } from '@jsverse/transloco';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatIcon, MatLabel, MatDialogContent, MatFormField, TranslocoModule, MatInput, FormsModule],
})
export class ConfirmDialogComponent {
  email: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialgoRef: MatDialogRef<ConfirmDialogComponent>,
    private userService: UserService,
    private uiService: UiService
  ) {
    if (this.data == null) {
      this.data = {};
    }
    if (this.data.title == null) this.data.title = DIALOG_CONFIRM_DEFAULT_TITLE;
    if (this.data.button_title == null) this.data.button_title = 'Conferma';
    if (this.data.extra_type == null) this.data.extra_type = '';
  }

  close() {
    this.dialgoRef.close(false);
  }

  confirm() {
    if (this.data.extra_type !== 'email') {
      this.dialgoRef.close(true);
      return;
    }

    this.uiService.showSpinner = true;
    this.userService
      .getFuturaUser()
      .pipe(
        finalize(() => {
          this.uiService.showSpinner = false;
          this.dialgoRef.close(true);
        }),
        switchMap(user => {
          user.content.email = this.email.trim();
          return this.userService.updateFuturaUser(user);
        })
      )
      .subscribe();
  }

  // Function to check if an email is valid using regex
  isValidEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email.trim());
  }

  isValid(): boolean {
    if (this.data.extra_type == 'email') return this.isValidEmail(this.email);

    return true;
  }
}
